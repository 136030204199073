export const CONSTANTS = {
    MAP_LATITUDE: -17.786612,
    MAP_LONGITUDE: -63.178121,
}

export const STEPPERS = {
    SERVICE: 'service',
    CALENDAR: 'calendar',
    PAYMENT: 'payment',
}

export const PaymentMethods = {
   QR : 'Qr simple',
   TRANSACTION :'Transacción bancaria',
   CASH :'Efectivo'

}
export const Key = {
    google: 'AIzaSyC0Tjtfuu3jGLUBpzcwF5Vq80BIEkDl2Bw',
}
export const GOOGLE_POSITION = {
    MAP_LATITUDE: -17.786612,
    MAP_LONGITUDE: -63.178121,
}
export const RELEASE = {
    prod: true,
    session_app_prod:'bela-app-pro',
    session_app_dev:'bela-app-dev',
}

export const getSessionAppName = () => {
  return RELEASE.prod ? RELEASE.session_app_prod : RELEASE.session_app_dev;
}

export const getSessionParent = () => {
    return 'parent-session';
}
