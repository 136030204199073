import {useState} from "react";
import {Header} from "../../layout/header";
import {Footer} from "../../layout/footer";
import {ContentList} from "./ContentList";
import {ContentRegister} from "./ContentRegister";
import {ContentEdit} from "./ContentEdit";
export interface Result {
  id: string
  action: 'list' | 'register' | 'edit';
  status: 'success' | 'error' | '';
  message: string | null;
}
export const initialStateResult: Result = {
  id: '',
  action: 'list',
  status: '',
  message: '',
};
export const AddressPage = () => {
  const [action, setAction] = useState(initialStateResult);
  const changeAction = (value: Result) => {
    setAction({...value});
  }
  const renderContent = () => {
    switch (action.action) {
      case "list":
        return  <ContentList onPress={(value: Result) => changeAction(value)}/>
        break;
      case "register":
        return   <ContentRegister onPress={(value: Result) => changeAction(value)}/>
        break;
      case "edit":
       return <ContentEdit onPress={(value: Result) => changeAction(value)} id={action.id}/>
        break;

    }
  }
  return <div>
    <Header />
      <div className={'container'}>
        {action.status == 'success' && <div className="alert alert-success" role="alert">
          {action.message}
        </div>}
        {renderContent()}
    </div>
    <Footer />
  </div>
}
