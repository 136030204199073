import {HeaderAppointment} from "../layout/headerAppointment";
import React, {useEffect, useState} from "react";
import {getCurrentUser} from "../services/LocalService";
import {editHttp, putHttp} from "../api/HttpClient";
import {buildUrlClient} from "../utils/BuildUrlClient";
import {ShowError} from "../compoments/showError";
import {onRegister} from "../store/thunks/authTrunk";
import {validateHelper} from "../helpers/validateHelper";
import {RegisterRules} from "../rules/RegisterRules";
import {STATUS_CODES} from "http";
import {CONSTANTS} from "../utils/Constants";

export const ProfilePage = () => {
  const [data, setData] = useState({
    name: '',
    last_name: '',
    mobile: '',
    nit: '',
    business_name: '',
  });
  const [errors, setErrors] = useState({
    name: '',
    last_name: '',
    mobile: '',
    nit: '',
    business_name:'',
  });
  useEffect(() => {
    (async () => await loadData())();
  }, []);
  const loadData = async () => {
    const currentSession = await getCurrentUser();
    const {response, success} = await editHttp(buildUrlClient('users'), currentSession?.id);
    if (success) {
      onBlurData(response);
    }
  }
  const onBlurData = (item: any) => {
    setData({...data, name: item.name, last_name: item.last_name, nit: item.nit, business_name: item.business_name});
  };
  const onChange = (event: any, field: string) => {
    // @ts-ignore
    data[field] = event.target.value;
    setData({...data});
    // onValidate(event.target.value, field);
  }
  const getRulesByField = (field: string) => {
    for (const [key, value] of Object.entries(RegisterRules)) {
      if (key === field) {
        return value;
      }
    }
    return [];
  }
  const onValidate = (value: any, field: string) => {
    const inputRules = getRulesByField(field);
    const resultValidate = validateHelper(field, value, inputRules, data);
    // @ts-ignore
    errors[field] = resultValidate;
    setErrors({...errors});
  };
  const checkValidate = async () => {
    for (const [key, value] of Object.entries(data)) {
      onValidate(value, key);
    }
  }
  const isValid = () => {
    let count = 0;
    for (let [key, value] of Object.entries(errors)) {
      if (value) {
        count = count + 1;
      }
    }
    return count;
  };
  const setMessageValidate = (key: string, value: string) => {
    // errors[key] = value;
    setErrors({...errors, [key]: value});
  };
  const onSubmit = async () => {
    const currentSession = await getCurrentUser();
    await checkValidate();
    if (isValid() == 0) {
      const newData = {
        name: data.name,
        last_name: data.last_name,
        mobile: data.mobile,
        nit: data.nit,
        business_name: data.business_name,
      }
      const body = {...newData, _method: 'put'};
      const {success, message, errors} = await putHttp(
          buildUrlClient('users'),
          currentSession?.id,
          body,
      );
      if (success) {
        alert(message)
      } else {
        const {code, error} = errors;
        if (code == 442) {
          for (let key in error) {
            setMessageValidate(key, error[key][0]);
          }
        }
      }

    }
  }
  return <>
    <HeaderAppointment />
    <div className={'container'}>
        <nav  aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item"><a href={'/'}>Inicio</a></li>
            <li className="breadcrumb-item active" aria-current="page">Editar Cuenta</li>
          </ol>
        </nav>
      <div className="col-lg-12">
        <div className="card  p-4 p-sm-5 position-relative">
          <form className="row g-3 position-relative">
            <div className="col-md-6 col-lg-12 col-xl-6">
              <label className="form-label">Nombre *</label>
              <input type="text" className="form-control"
                     value={data.name}
                     onChange={event => onChange(event, 'name')}/>
              <ShowError message={errors.name} />
            </div>
            <div className="col-md-6 col-lg-12 col-xl-6">
              <label className="form-label">Apellidos </label>
              <input type="text" className="form-control"
                     value={data.last_name}
                     onChange={event => onChange(event, 'last_name')}/>
              <ShowError message={errors.last_name} />
            </div>
            <div className="col-md-6 col-lg-12 col-xl-6">
              <label className="form-label">Nro. Celular *</label>
              <input type="email" className="form-control"
                     value={data.mobile}
                     onChange={event => onChange(event, 'mobile')}/>
              <ShowError message={errors.mobile} />
            </div>
            <div className="col-md-6 col-lg-12 col-xl-6">
              <label className="form-label">Nit. </label>
              <input type="email" className="form-control"
                     value={data.nit}
                     onChange={event => onChange(event, 'nit')}/>
              <ShowError message={errors.nit} />
            </div>

            <div className="col-md-12 col-lg-12 col-xl-12">
              <label className="form-label">Razón social</label>
              <input type="email" className="form-control"
                     value={data.business_name}
                     onChange={event => onChange(event, 'business_name')}/>
              <ShowError message={errors.business_name} />
            </div>
            <div className="col-12">
              <button type="button" className="btn btn-success mb-0 w-100" onClick={() => onSubmit()}>Enviar</button>
            </div>
          </form>
        </div>
      </div>

    </div>
  </>
}
