import React from "react";

export const ForgotPassword = () => {
  //
  // const openApp = async () => {
  //   try {
  //     const supported = await Linking.canOpenURL(url);
  //     if (supported) {
  //       await Linking.openURL(url);
  //     }
  //   } catch (error) {
  //  console.log(' lista  ', error);
  //   }

  return <div>
    <section className="p-0 d-flex align-items-center position-relative overflow-hidden">

      <div className="container-fluid">
          <div className="col-12 col-lg-12 d-flex justify-content-center">
            <div className="row my-5">
              <div className="col-sm-10 col-xl-12 m-auto">
                <h1 className="fs-2">Bela2go</h1>
                <form>
                  <br/>
                  <div className="align-items-center">
                    <div className="d-grid">
                      <a className={'btn btn-primary mb-0'} href="bela2goclient://launch">Iniciar en la  App </a>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
    </section>
  </div>;
}
