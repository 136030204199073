import {useParams} from "react-router-dom";
import {Header} from "../../layout/header";
import React, {useEffect, useState} from "react";
import {HeaderBasic} from "../../layout/headerBasic";
import {ShowError} from "../../compoments/showError";
import {validateHelper} from "../../helpers/validateHelper";
import {RegisterRules} from "../../rules/RegisterRules";
import {onRegister} from "../../store/thunks/authTrunk";
import {getHttp} from "../../api/HttpClient";
import {buildUrlBase} from "../../utils/BuildUrlBase";

export const ForgotPage = () => {

  const { key } = useParams();
  const [data, setData] = useState({
    password: '',
    confirmPassword:'',
  });
  const [errors, setErrors] = useState({
    password: '',
    confirmPassword:'',
  });
  console.log(' key ', key);
  useEffect(() => {
    (async () => await checkData())();
  },[]);
  const checkData = async () => {
    const params = {
      password: data.password,
      key: key,

    }
    const {response, success} = await getHttp(buildUrlBase(''), params);
    if (success) {

    }
  }
  const onChange = (event: any, field: string) => {
    // @ts-ignore
    data[field] = event.target.value;
    setData({...data});
    onValidate(event.target.value, field);
  }
  const onValidate = (value: any, field: string) => {
    const inputRules = getRulesByField(field);
    const resultValidate = validateHelper(field, value, inputRules, data);
    // @ts-ignore
    errors[field] = resultValidate;
    setErrors({...errors});
  };
  const getRulesByField = (field: string) => {
    for (const [key, value] of Object.entries(RegisterRules)) {
      if (key === field) {
        return value;
      }
    }
    return [];
  }
  const checkValidate = async () => {
    for (const [key, value] of Object.entries(data)) {
      onValidate(value, key);
    }
  }
  const isValid = () => {
    let count = 0;
    for (let [key, value] of Object.entries(errors)) {
      if (value) {
        count = count + 1;
      }
    }
    return count;
  };
  const onSubmit = async () => {
    await checkValidate();
    if (isValid() == 0) {
      const body = {
        password: data.password,
      }
      console.log(' body ', body);
      // dispatch(onRegister(body));
    }
  }
  return <>
    <HeaderBasic />
    <div className={'container'}>
      <nav  aria-label="breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item"><a href={"/"}>Inicio</a></li>
          <li className="breadcrumb-item active" aria-current="page">Cambiar la contraseña</li>
        </ol>
      </nav>
    </div>
    <div className={"container"}>
      <div className="col-lg-12">
        <div className="card  p-4 p-sm-5 position-relative">
          <form className="row g-3 position-relative">

            <div className="col-md-12 col-lg-12 col-xl-12">
              <label className="form-label">Contraseña *</label>
              <input type="email" className="form-control" onChange={event => onChange(event, 'password')}/>
              <ShowError message={errors.password} />
            </div>
            <div className="col-md-12 col-lg-12 col-xl-12">
              <label className="form-label">Confirmar contraseña *</label>
              <input type="email" className="form-control" onChange={event => onChange(event, 'confirmPassword')}/>
              <ShowError message={errors.confirmPassword} />
            </div>
            <div className="col-12 text-center">
              <button type="button" className="btn btn-success mb-0 w-50" onClick={() => onSubmit()}>Enviar</button>
            </div>
          </form>
        </div>
      </div>
    </div>

  </>
}
