import {Header} from "../layout/header";
import {Footer} from "../layout/footer";
import {Types} from "../compoments/Types";
import {Featured} from "../compoments/Featured";
import {Provider} from "../compoments/Provider";
import {Welcome} from "../compoments/Welcome";
import {CarouselApp} from "../compoments/CarouselApp";
import {Button, Spinner} from "react-bootstrap";
import {useAppDispatch, useAppSelector} from "../hooks/hook";
import {RootState} from "../store/store";
import {CarouselPromotion} from "../compoments/CarouselPromotion";
import React, {useEffect} from "react";
import {setServices} from "../store/slices/appointmentSlice";
import {SearchAutocomplete} from "../compoments/SearchAutocomplete";
import {postHttp} from "../api/HttpClient";
import {buildUrlClient} from "../utils/BuildUrlClient";
import {setLogin, setParentLogin} from "../store/slices/authSlice";
import DatePickerApp from "../compoments/DatePickerApp";
export const HomePage = () => {
  const dispatch = useAppDispatch();
  const {isLoading} = useAppSelector((state: RootState) => state.providers);
  const {status, showModal, session, parentId} = useAppSelector((state: RootState) => state.auth);
  useEffect(() => {
    (async () => await resetData())();
  }, []);
  const changeAccount = async () => {

    // eslint-disable-next-line no-restricted-globals
    const result = confirm('¿Está seguro de cambiar de usuario?');
    if (result) {
      const body = {id: parentId}
      const {response, success} = await postHttp(buildUrlClient('loginById'), body );
      if (success) {
        dispatch(setLogin({status: 'authenticated', message: "",
          session: {id: response.id,
            name: response.name,
            last_name:response.last_name,
            token: response.auth_token,
            is_support: response.is_support}}));
        dispatch(setParentLogin({parentId: ''}))
      }
    }
  }
  const initSession = async (client: any) => {
    const body = {id: client.id}
    const {response, success} = await postHttp(buildUrlClient('loginById'), body );
    console.log('response ', response);
    if (success) {
      dispatch(setLogin({status: 'authenticated', message: "",
        session: {id: response.id,
          name: response.name,
          last_name:response.last_name,
          token: response.auth_token,
          is_support: response.is_support}}));
      dispatch(setParentLogin({parentId: session.id}))

      console.log(' session ', session);
      console.log(' seleccionado ', client);
    }
  }
  const resetData = () => {
    dispatch(setServices({services: []}));
  }
  const onSelectedDay = (selectDay: any) => {
    console.log(' selected ', selectDay);
  }

  return <>
    <Header />
    <div className={'container'}>
      {status == 'authenticated' &&<div className={'row p-2'}>
        <div className={'col-6'}>
          {session?.is_support == 1 && <SearchAutocomplete
              visible={true} value={''}
              placeholder={'Escribe'}
              onClose={() => console.log('Lista')}
              onSave={(client: any) => initSession(client)} />}
        </div>
        <div className={'col-6 text-end'}>
          {parentId != '' && <Button variant="primary" onClick={() => changeAccount()}>
            Cambiar al usuario principal
          </Button>}
        </div>
      </div>}
      <Welcome />
    </div>
    <div className={"container"}>
      {/*<DatePickerApp getSelectedDay={(value: any) =>  onSelectedDay(value)}*/}
      {/*               selectDate={new Date()}*/}
      {/*               endDate={30}*/}
      {/*               labelFormat={"MMMM es"}*/}
      {/*               color={"#17b5b7"}*/}
      {/*/>*/}
    </div>
    <div className={'container'}>
    <Types />
    </div>
    {isLoading &&  <div className={'container text-center'}>
      <Spinner animation="border" role="status">
        <span className="visually-hidden">...</span>
      </Spinner>
    </div>}
      <CarouselPromotion />
    <div className={'container'}>
      <div className="col-12 d-sm-flex justify-content-between align-items-center " style={{paddingBottom: 10}}>
        <h2 className="mb-0">Profesionales mas populares</h2>
        <a className="btn btn-sm btn-success-soft mb-0" href={'/featured'}>Ver todos</a>
      </div>
      <CarouselApp />
    </div>
    <div className={'container'}>
        <Provider  />
    </div>
    <Footer />
  </>
}
