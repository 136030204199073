import React, {useEffect, useState} from "react";
import {useAppDispatch, useAppSelector} from "../hooks/hook";
import {RootState} from "../store/store";
import {PaymentMethods, STEPPERS} from "../utils/Constants";
import {getAppointments, getCodeApplyAppointments} from "../store/thunks/appointmentThunk";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faHome} from "@fortawesome/free-solid-svg-icons";
import {typePayment} from "../data/Data";
import moment from "moment";
import {setLoading, setPaymentMethod, setShowModalAddrress} from "../store/slices/appointmentSlice";
import {GoogleModal} from "./GoogleModal";
import {AddressModal} from "./AddressModal";
import {SpinnerApp} from "./SpinnerApp";

export const Payment = () => {
    const dispatch = useAppDispatch();
    const [visible, setVisible] = useState(false);
    const {stepper, payment, promotion, showModalAddrress, isLoading} = useAppSelector((state: RootState) => state.appointment);
    const {appointmentList, paymentMethod} = payment;
    const [paymentSelected, setPaymentSelected] = useState('Qr simple');
    const [codeDiscount, setCodeDiscount] = useState('');
    useEffect(() => {
        (async () => await loadData())();
    }, []);
    const loadData = () => {
        const {appointments} = payment;
        console.log(' stepper pay ', stepper);
        const ids = appointments?.map((item: any) => item.appointment_id).toString();
        dispatch(getAppointments(ids, promotion));
        // if (stepper === STEPPERS.PAYMENT) {
        //     const ids = appointments?.map((item: any) => item.appointment_id).toString();
        //     dispatch(getAppointments(ids, promotion));
        // }
    }
    const onAddress = (value: any) => {
        dispatch(setShowModalAddrress({showModalAddrress: true}))
      setVisible(true);
    }
    const onCloseModal = () => {
        // showModalAddrress
        dispatch(setShowModalAddrress({showModalAddrress: false}))
        setVisible(false);
    }
    const RenderAddress = () => {
        const {address} = appointmentList as any;
      return <div className="col-lg-12 col-xxl-12">
              <div className="h-100">
                  <div className="card-body p-4">
                      <div className="d-sm-flex justify-content-between align-items-center">
                          <div className="d-sm-flex align-items-center mb-1 mb-sm-0">
                              <div className="icon-lg rounded-circle bg-success text-white mb-0">
                                  <FontAwesomeIcon icon={faHome}  size={'1x'}/>
                              </div>
                              <div className="ms-0 ms-sm-2 mt-2 mt-sm-0">
                                  <h5 className="mb-1">Dirección: {address?.street}</h5>
                                  <h6 className="mb-1">Referencias: {address?.reference_detail}</h6>
                                  <ul className="list-inline mb-0 small">
                                      <li className="list-inline-item fw-light me-2 mb-1 mb-sm-0"><i
                                          className="fas fa-book text-purple me-1"></i>Nro. {address?.number}
                                      </li>
                                  </ul>
                              </div>
                          </div>
                          <button className="btn btn-sm btn-success mb-0" onClick={() => onAddress(address)}>Cambiar</button>
                      </div>
                  </div>
              </div>
          </div>
    }
    const onSelectedPayment = (item: string) => {
      setPaymentSelected(item);
      dispatch(setPaymentMethod({paymentMethod: item}))
    }

    const paymentMethodDetail = (method: string) => {
        switch (method) {
            case PaymentMethods.QR:
                return <div>
                    <p> Con el QR. generado podrá realizar el pago desde cualquier de tus cuentas bancarias.</p>
                </div>;
            case PaymentMethods.CASH:
                return <div>
                    <p> El pago lo realizará en efectivo.</p>
                </div>;
            case PaymentMethods.TRANSACTION:
                return <div>
                    <p>La cuenta a realizar la transaccion.</p>
                    <p >Empresa : FASCINET S.R.L. </p>
                    <p  >Dirección : Calle Libertad Edif. Punta del Este - PB5 </p>
                    <p  >Nit : 374776027 </p>
                    <p  >Banco : BCP </p>
                    <p >Número de cuenta : 701-5087173-3-22{' '}</p>

                </div>
        }
      return <></>
    }
const RenderPayment = () => {
  return <div>
      <div className="bg-transparent rounded-3 mb-4 z-index-9">
          <div className="card-header p-0">
              <h2 className="mb-1">Métodos de pago</h2>
          </div>
          <div className="row accordion accordion-circle payment-content" id="accordioncircle">
              {typePayment.map((item: any, index: number) => (
                   <div  className="accordion-item" key={index}>
                      <h6 className="accordion-header font-base" id={`heading-${index}`}>
                          <button className="accordion-button bg-white rounded collapsed" type="button"
                                  onClick={() => onSelectedPayment(item.value)}
                                  data-bs-toggle="collapse" data-bs-target={`#collapse-${index}`} aria-expanded={`${item.value == paymentMethod ? "true" : "false"}`}
                                  aria-controls={`#collapse-${index}`}>
                              {item.label}
                          </button>
                      </h6>
                      <div id={`collapse-${index}`} className={`accordion-collapse collapse  ${item.value == paymentMethod ? "show" : ""}`} aria-labelledby={`#heading-${index}`}
                           data-bs-parent="#accordioncircle">
                          <div className="accordion-body">
                              {paymentMethodDetail(item.value)}
                          </div>
                      </div>
                      </div>
              ))}
              </div>
      </div>
  </div>
}

const RenderDateTime = (props: any) => {
  return  <div className="mb-2">
      <h5 className="m-0">{props.services[0]?.professional?.name}</h5>
      <span className="me-3 small"> {moment(props[0]?.date_scheduled).format('DD [de] MMMM [de] YYYY',)} at 11:55 am</span>
  </div>
}
const onApplyCoupon = () => {
    const {appointments} = payment;
    const ids = appointments?.map((item: any) => item.appointment_id).toString();
    if (codeDiscount != '') {
        dispatch(setLoading({loading: true}));
        dispatch(getCodeApplyAppointments(ids, codeDiscount))
    } else {
        alert('Ingrese el código de descuento')
    }
}
const onChangeCode = (event: any) => {
  setCodeDiscount(event.target.value);
}
const RenderService = () => {
        const {appointments} = appointmentList as any;
  return <div>
      { promotion.id == undefined  && <div className="mb-3">
          <div className="d-flex justify-content-between align-items-center">
              <span>Ingrese el Código </span>
              {/*<p className="mb-0 h6 fw-light">AB12365E</p>*/}
          </div>
          <div className="input-group mt-2">
              <input className="form-control form-control" placeholder="Código de Descuento"
                     onChange={(value: any) => onChangeCode(value)}/>
              <button type="button" className="btn btn-success" onClick={() => onApplyCoupon()}>Aplicar</button>
          </div>

      </div>
      }
      <div className="card-header border-bottom p-0">
          <h2 className="mb-1">Servicios</h2>
      </div>
      {appointments?.map((schedule: any, index: number) => (
          <div key={index} className="border-bottom">
              <ul className="list-group list-group-borderless mb-2">
                  <div className="my-4 d-flex">
                      <img className="avatar avatar-md rounded-circle me-3" src={`${schedule?.appointment_services[0]?.professional?.perfilLink}`}
                           alt="avatar" />
                      <div className={'w-100'}>
                         <RenderDateTime services={schedule?.appointment_services} />
                          <ul className="list-group list-group-borderless mb-2">
                              {schedule?.appointment_services?.map((service: any, key: number) => (

                                      <li key={key} className="list-group-item px-0 d-flex justify-content-between">
                                          <span className="h6 fw-light mb-0">{service?.name}</span>
                                          <span className="h6 fw-light mb-0 fw-bold">${service?.cost}</span>
                                      </li>
                              ))}
                          </ul>
                      </div>
                  </div>
              </ul>
          </div>
      ))}
  </div>
}
const RenderResult = () => {
    const {result} = appointmentList as any;
  return <div>
      <ul className="list-group list-group-borderless mb-2">
          <li className="list-group-item px-0 d-flex justify-content-between">
              <span className="h6 fw-light mb-0">Subtotal</span>
              <span className="h6 fw-light mb-0 fw-bold">${result?.total_cost}</span>
          </li>
          <li className="list-group-item px-0 d-flex justify-content-between">
              <span className="h6 fw-light mb-0">Transporte</span>
              <span className="h6 fw-light mb-0 fw-bold">${result?.transport_cost}</span>
          </li>

          <li className="list-group-item px-0 d-flex justify-content-between">
              <span className="h6 fw-light mb-0">Descuento</span>
              <span className="text-danger">-${result?.discount}</span>
          </li>
          <li className="list-group-item px-0 d-flex justify-content-between">
              <span className="h5 mb-0">Total</span>
              <span className="h5 mb-0">${result?.total}</span>
          </li>
      </ul>
  </div>
}
    return <div className={'row'}>
        <RenderAddress />
        <RenderPayment/>
        <RenderService />
        <div className={'row text-center'}>
            {isLoading && <div className="span6" style={{float: 'none', margin: '0 auto',}}>
                <SpinnerApp loading={isLoading} />
            </div>}
        </div>

        <RenderResult />
    </div>
}
